$redMain: #d3455b;
$greenMain: #006837;
$greenSecond: #3f8e9a;
$greenThird: #0f8e99;
$greenThirdDark: #086169;
$greenFourth: #215f00;
$greyHighlight: #f8f8f8;
$greyMain: #333333;
$greySecond: #707070;
$greyThird: #d1d9e1;
$greyLight: #9d9a9a;
$greyBackground: #f7f7f7;
$yellowMain: #fffbeb;
$yellowHighlight: #fad433;
$difficultyEasy: #86e500;
$difficultyMedium: #f7df00;
$difficultyHard: #cc0507;
$homePanelText: #f7f7f7;
$hintActive: #f4d808;
$hintInactive: #768ca5;
$inputBorder: #d0d0d0;
$homePanelMain: #1a3f69;
$yellowSecond: #fce908;
$questionIcon: #6d9ce8d8;
$questionTag: #52c2cc50;
$difficulty_1: #86e500;
$difficulty_2: #03e577;
$difficulty_3: #00c5e8;
$difficulty_4: #f7df00;
$difficulty_5: #f09e04;
$difficulty_6: #f05d0d;
$difficulty_7: #cc0507;
$lightThemeTextColor: #10161a;
$lightThemeBackgroundColor: white;
$activeButton: #52c2cc;
$requiredColor: #dc3545;
$pinkMain: #fa8f8f;
