@import "../../Resources/Colors.scss";

.firstColumn {
    align-self: stretch;
    align-items: flex-start;
}

.questionNumber {
    display: flex;
    font-size: 1.1rem;
    color: black;
    width: 32px;
    align-items: center;
    flex-direction: row;
}

.questionText {
    color: black;

    p {
        width: 100%;
        margin-bottom: 0.5rem;
    }
}

.questionTextLighter {
    display: flex;
    color: darkgray;
}

.questionTextContainer {
    flex: 1;
    align-items: stretch;
}

.questionHintContainer {
    align-items: flex-start;
}

.hintIcon {
    width: 18px;
    height: 18px;
}

.hintText {
    font-size: 0.7rem;
    color: $greyLight;
    margin-left: 4px;
}

.hintTextEditor {
    font-size: 0.7rem;
    color: $greyLight;
    margin-left: 18px;
    margin-top: 6px;
    margin-right: 12px;
    flex: 1;
}

.questionIcon {
    width: 18px;
    height: 18px;
    margin: 2px;
}

.tag {
    background-color: $questionTag;
    border: 1px solid $greySecond;
    border-radius: 10px;
    padding: 2px 6px;
    margin: 2px;
    color: black;
    font-size: 0.7rem;
    font-weight: 300;
}

.description {
    color: black;
    font-size: 1rem;
}

.descriptionEditor {
    color: black;
    font-size: 1rem;
    margin-left: 12px;
}

.linkIcon {
    width: 12px;
    height: 12px;
    margin-left: 4px;
}

.answerText {
    font-size: 0.9rem;
    color: $greenMain;
    margin-top: 4px;
    padding-left: 24px;
}

.answerTextEditor {
    font-size: 0.9rem;
    color: $greenMain;
    margin-top: 6px;
    margin-left: 18px;
    margin-right: 12px;
}

.difficultyLevel {
    display: block;
    width: 6px;
    height: 12px;
}

.minimal {
    grid-template-columns: 8fr 2fr 2fr;
    grid-template-areas: "col1 col2 col3";
}

.small {
    grid-template-columns: 8fr 2fr 2fr;
    grid-template-areas: "col1 col2 col3";
}

.large {
    grid-template-columns: 5fr 2fr 2fr 3fr;
    grid-template-areas: "col1 col2 col3 col4";
}

.radioItem {
    font-size: 0.8rem;
    margin-top: 0;
    margin-bottom: 3px;
}

.questionIconEdit {
    width: 18px;
    height: 18px;
    margin-left: 6px;
}

.reverseSwitch {
    font-size: 0.8rem;
    margin-left: 26px;
    margin-top: 0;
    margin-bottom: 6px;
}

.borderedBox {
    border: 1px solid transparent;
    padding: 12px;
}

.headline {
    font-size: 1rem;
    font-weight: 500;
}

// better way of determining size: currently size, preview and probably more

.gridWideNoAnswer {
    display: grid;
    grid-template-columns: 6fr 2fr 2fr 2fr;
    grid-template-areas:
        "question category tags ce"
        "questionimage questionimage questionimage questionimage"
        "hint category tags ce";
    padding: 12px;
}

.gridWideBulkEdit {
    display: grid;
    grid-template-columns: 50px 6fr 2fr 2fr 2fr;
    grid-template-areas: "checkbox question category tags ce" "questionimage questionimage questionimage questionimage questionimage";
    padding: 12px;
}

/* "questionimage questionimage questionimage questionimage"
 "answerimage answerimage answerimage answerimage";
*/

.gridWideWithAnswers {
    display: grid;
    grid-template-columns: 6fr 2fr 2fr 2fr;
    grid-template-areas:
        "question category tags ce"
        "hint category tags ce"
        "questionimage questionimage tags ce"
        "answer answer tags ce"
        "answerimage answerimage tags ce";
    padding: 12px;
}

.gridNarrowSmall {
    display: grid;
    grid-template-columns: 5fr 2fr 2fr 4fr;
    grid-template-areas: "question questionimage category tags";
    padding: 12px;
}

.gridNarrowFull {
    display: grid;
    grid-template-columns: 6fr 2fr 4fr;
    grid-template-areas:
        "question category tags"
        "questionimage questionimage tags"
        "hint hint tags"
        "answer answer tags"
        "answerimage answerimage answerimage";
    padding: 12px;
}

// TODO: fix the edit setup for questions with images

.gridWideEdit {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 5fr 2fr 2fr 1fr 20px;
    grid-template-areas:
        "question       category    ce              ce              close"
        "hint           category    tags            tags            ."
        "answer         category    .               .               ."
        "alwaysinanswer .           .               .               ."
        "questionimage questionimage savechanges     savechanges     savechanges"
        "answerimage answerimage savechanges     savechanges     savechanges"
        "difficulty     .           unfinishedcheck options         options";
    padding: 12px;
    border: 1px solid black;
}

.gridNarrowEdit {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 20px;
    grid-template-areas:
        "question category category close"
        "hint category category ."
        "answer tags tags ."
        "questionimage questionimage questionimage ."
        "answerimage answerimage answerimage ."
        "alwaysinanswer  . . ."
        "difficulty difficulty savechanges savechanges"
        "ce unfinishedcheck options options";
    padding: 12px;
    border: 1px solid black;
}

.gridPreview {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
        "question category"
        "questionimage questionimage"
        "hint tags"
        "answer tags"
        "answerimage answerimage"
        "ce ce";
}

.divcontainingimage {
    padding: 1px;
}

.divcontainingimage > div > img {
    max-height: 400px;
    max-width: 400px;
}

.divcontainingimage > img {
    max-height: 400px;
    max-width: 400px;
}

.editContainer {
    display: flex;
    flex-flow: row wrap;
}

.editImageContainer {
    display: flex;
    flex-flow: row wrap;
    padding-top: 15px;
}

.editTextInput {
    display: flex;
    flex: 1 1 300px;
}

.editImageInput {
    display: flex;
    margin-left: 15px;
}

.categoryGrid {
    display: grid;
    grid-area: category;
    justify-items: center;
}

.tagsGrid {
    display: grid;
    grid-area: tags;
    justify-items: center;
    align-items: flex-start;
}
