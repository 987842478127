@import "./Colors.scss";
// @import "bootstrap/scss/bootstrap.scss";
@import "~animate.css/source/animate.css";

$valearn-sans-serif: macho, "Museo-Sans", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

.button {
    min-width: 192px !important;

    &.none {
        background-color: white !important;
    }

    &.success {
        // background-color: $greenSecond !important;
        min-height: 32px !important;
    }
}

.select {
    min-width: 192px !important;
    background-color: white;
}

button:focus,
input:focus,
textarea:focus {
    outline: none !important;
}

.bp3-menu {
    background-color: white !important;
}

.bp3-heading {
    font-weight: 500 !important;
}

.fade-enter {
    opacity: 0;
}
.fade-enter-active {
    opacity: 1;
    transition: opacity 200ms;
}
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
    transition: opacity 200ms;
}

.gridBasic {
    display: grid;
}

body {
    background: $lightThemeBackgroundColor;
    font-family: $valearn-sans-serif;
    color: $lightThemeTextColor;
    width: 100vw;
    height: 100vh;
    line-height: 1.28581;
    overflow-x: hidden;
}

.bp3-menu {
    max-height: 256px !important;
    overflow-x: auto !important;
}

.bp3-button-text {
    flex: 1 !important;
}

.textCenter {
    text-align: center;
}

.underlined {
    text-decoration: underline;
}

.grecaptcha-badge {
    width: 70px !important;
    overflow: hidden !important;
    transition: all 0.3s ease !important;
    left: 4px !important;
}

.grecaptcha-badge:hover {
    width: 256px !important;
}

.Sticky-React-Table--Header-Column-Switcher {
    display: none;
}

.fixedFooter {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
}
