bp4-form-content {
    width: 80%;
}

/* .bp4-popover-wrapper.fullwidth,
.bp4-popover-wrapper.fullwidth > .bp4-popover-target {
    display: block;
} */

span.bp4-popover-wrapper,
span.bp4-popover-wrapper > .bp4-popover-target {
    display: block;
}

.fixcurr.bp4-popover-content {
    margin-left: 150px;
}

.bp4-multi-select-popover .bp4-popover-content .bp4-menu {
    max-height: 300px;
    max-width: 400px;
    overflow: auto;
    /* padding-left: 170px; */
}
